






























import Vue from "vue";
import create from "./components/create.vue";
import { getAll, del } from "@/api/menu";

export default Vue.extend({
  name: "MenuIndex",
  components: {
    create
  },
  data() {
    return {
      list: []
    };
  },
  created() {
    this.getList();
  },
  methods: {
    indexMethod(index: number) {
      // return (this.page.current - 1) * 10 + index + 1;
      return index + 1;
    },
    async getList() {
      const { data } = await getAll();
      if (data.code === "000000") {
        this.list = data.data;
      } else {
        this.$message.error(data.mesg);
      }
    },
    del(id: number) {
      this.$confirm("确认删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const { data } = await del(id);
          if (data.code === "000000") {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(data.mesg);
          }
        })
        .catch(() => {
          // 取消执行这里
        });
    }
  }
});
